<template>
	<div>

		<div class="my-2">
			<router-link v-if="canWrite('spinners')" :to="{
			name: 'projects-editor-uuid-cuuid-tests-spinner-new',
			params: {
				uuid: $route.params.uuid,
			   cuuid: $route.params.cuuid
			}
		}" class="btn btn-link">
				<i class="fa fa-plus"></i>
				NEW TEST
			</router-link>
			<button class="btn btn-link" @click.prevent="loadTests">
				<i class="fa fa-sync-alt" :class="{'fa-spin': loading}"></i>
				RELOAD
			</button>
		</div>

		<spinners-table :tests="tests">
			<template v-slot="{test}" v-if="canWrite('spinners')">
				<button :disabled="deleting" class="btn btn-link text-danger"
						@click.prevent.stop="deleteTest(test.Id)">
					<i
							class="fa fa-trash"></i></button>
			</template>
		</spinners-table>
	</div>
</template>

<script>
	import api from '@/api'
	import messageBox from '@/components/modals/alert'
	import SpinnersTable from "../../../../../../../components/spinner/spinners-table";

	export default {

		data() {
			return {
				loading: false,
				tests: [],
				deleting: false,
				reloadInterval: null
			}
		},

		created() {
			this.reloadInterval = setInterval(this.loadTests, 10000)
		},

		destroyed() {
			clearInterval(this.reloadInterval)
		},

		methods: {
			async deleteTest(test_id) {
				this.deleting = true
				this.loading = true
				await api.Spinner.delete(test_id).catch(() => this.deleting = false)

				messageBox({
					message: `Test #${test_id} deleted`
				})

				await this.loadTests()
				this.deleting = false
			},
			async loadTests() {
				this.loading = true
				this.tests = (await api.Spinner.getSpinnerTestList(this.$route.params.cuuid).catch(() => this.loading = false))


				/*
				this.tests.forEach(t => {
					if (this.openTests[t.Id] === undefined) {
						this.openTests[t.Id] = t.Status === 'running'
					}
					return t
				})
				 */

				this.loading = false
			}
		},

		async mounted() {
			this.loadTests()
		},

		components: {
			SpinnersTable,
		}

	}
</script>
