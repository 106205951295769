var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"my-2"},[(_vm.canWrite('spinners'))?_c('router-link',{staticClass:"btn btn-link",attrs:{"to":{
		name: 'projects-editor-uuid-cuuid-tests-spinner-new',
		params: {
			uuid: _vm.$route.params.uuid,
		   cuuid: _vm.$route.params.cuuid
		}
	}}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v(" NEW TEST ")]):_vm._e(),_c('button',{staticClass:"btn btn-link",on:{"click":function($event){$event.preventDefault();return _vm.loadTests.apply(null, arguments)}}},[_c('i',{staticClass:"fa fa-sync-alt",class:{'fa-spin': _vm.loading}}),_vm._v(" RELOAD ")])],1),_c('spinners-table',{attrs:{"tests":_vm.tests},scopedSlots:_vm._u([(_vm.canWrite('spinners'))?{key:"default",fn:function({test}){return [_c('button',{staticClass:"btn btn-link text-danger",attrs:{"disabled":_vm.deleting},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.deleteTest(test.Id)}}},[_c('i',{staticClass:"fa fa-trash"})])]}}:null],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }